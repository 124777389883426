export type EmptyObject = Record<string, never>

export type PartialRecord<K extends keyof never, T> = {
  [P in K]?: T
}

export type StaticDocumentTypes =
  | 'ACC-OPENING-APPLICATION'
  | 'DATA-PROC'
  | 'EVAL-CONSENT'
  | 'SPOUSE-CONSENT-CAR'
  | 'SPOUSE-CONSENT-REAL-ESTATE'

export enum RepaymentMethods {
  annuity = 'ANNUITY',
  equalInstallment = 'EQUAL_INSTALMENTS'
}

export enum PledgeTypes {
  realEstate = 'real_estate',
  car = 'car'
}

export enum NotificationTypes {
  serverNotification = 'server_notification',
  commandProcessingResult = 'command_processing_result'
}

export interface EventNotification {
  key: string
  type: NotificationTypes.serverNotification
  payload: {
    code: EventTypes
    is_final: boolean
    data: unknown
  }
}

export interface CommandProcessingResultNotification {
  key: string
  type: NotificationTypes.commandProcessingResult
  value: string
}

export type SocketNotifications = EventNotification | CommandProcessingResultNotification

export enum EventTypes {
  borrower_otp_verification_attempts_exceeded = 'borrower_otp_verification_attempts_exceeded', // Закончились попытки ввода заемщиком ОТП кода
  borrower_otp_code_incorrect = 'borrower_otp_code_incorrect', // Код проверки телефона неверный
  borrower_otp_code_successful = 'borrower_otp_code_successful', // Номер телефона проверен успешно
  internal_checks_successful = 'internal_checks_successful', // Внутренние проверки успешны
  all_checks_successful = 'all_checks_successful', // Все проверки прошли успешно
  need_take_car_photo = 'need_take_car_photo', // Необходимо сфотографировать машину
  necessary_confirm_choosen_conditions = 'necessary_confirm_choosen_conditions', // Необходимо подтвердить выбранные условия
  borrower_can_confirm_choosen_conditions = 'borrower_can_confirm_choosen_conditions', // Заемщик может подтверждать условия
  borrower_cannot_confirm_choosen_conditions = 'borrower_cannot_confirm_choosen_conditions', // Нельзя подтверждать условия до утра рабочего дня
  pledge_approved = 'pledge_approved', // Залог одобрен Залоговиком
  borrower_needs_upload_real_estate_documents = 'borrower_needs_upload_real_estate_documents', // Необходимо загрузить документы на недвижимость
  necessary_provide_spouse_details = 'necessary_provide_spouse_details', // Необходимо предоставить данные супруги
  spouse_not_own_the_number_provided = 'spouse_not_own_the_number_provided', // Номер телефона не принадлежит супруге
  spouse_own_the_number_provided = 'spouse_own_the_number_provided', // Номер телефона принадлежит супруге
  spouse_agreed_loan = 'spouse_agreed_loan', // Супруг дал согласие
  borrower_necessary_provide_access_digital_documents = 'borrower_necessary_provide_access_digital_documents', // Необходимо предоставить доступ к ЦД
  borrower_digital_documents_code_incorrect = 'borrower_digital_documents_code_incorrect', // Код неверный для ddocs
  borrower_attempts_digital_documents_code_are_over = 'borrower_attempts_digital_documents_code_are_over', // Закончились попытки ввода заемщиком кода для ddocs
  registration_in_banking_system_started = 'registration_in_banking_system_started', // Началась регистрация в банковской системе
  registration_in_bank_system_completed = 'registration_in_bank_system_completed', // Регистрация в системе банка пройдена
  documents_generated = 'documents_generated', // Документы сгенерированы
  document_validation_success = 'document_validation_success', // Проверка документов была пройдена успешна
  need_wait_work_time = 'need_wait_work_time', // Нельзя подписывать до утра рабочего дня
  borrower_need_initiate_documents_signing = 'borrower_need_initiate_documents_signing', // Необходимо инициировать подписание документов НОВОЕ ПОДПИСАНИЕ
  borrower_need_initiate_documents_signing_forensics = 'borrower_need_initiate_documents_signing_forensics', // Необходимо инициировать подписание документов Forensics
  borrower_video_identification_failure_forensics = 'borrower_video_identification_failure_forensics', //Видео идентификация Forensics прошла неуспешно
  borrower_video_identification_successful_forensics = 'borrower_video_identification_successful_forensics', // Видео идентификация Forensics прошла успешно
  borrower_need_initiate_documents_signing_did_aitu = 'borrower_need_initiate_documents_signing_did_aitu', // Необходимо инициировать подписание документов DidAitu
  borrower_signature_confirmation_otp_code_successful = 'borrower_signature_confirmation_otp_code_successful', // Код подтверждения подписи успешен
  borrower_signature_confirmation_otp_code_incorrect = 'borrower_signature_confirmation_otp_code_incorrect', // Код подтверждения подписи неверный
  borrower_signature_confirmation_otp_verification_attempts_exceeded = 'borrower_signature_confirmation_otp_verification_attempts_exceeded', // Закончились попытки ввода кода подтверждения подписи
  redirect_borrower_to_video_identification = 'redirect_borrower_to_video_identification', // Перенаправить заемщика на видео идентификацию
  borrower_video_identification_failure = 'video_identification_failure', // Видео идентификация прошла неуспешно
  borrower_video_identification_successful = 'video_identification_successful', // Видео идентификация прошла успешно
  borrower_need_wait_spouse_signature = 'borrower_need_wait_spouse_signature', // Необходимо ожидать подписи супругом 
  borrower_internal_checks_continue = 'borrower_internal_checks_continue', // Дополнительные внутренние проверки продолжаются
  borrower_pledge_continue = 'borrower_pledge_continue', // Ожидать постановки собственности в залог
  loan_successfully_processed = 'loan_successfully_processed', // Кредит оформлен, средства начислены
  loan_refusal = 'loan_refusal' // Отказ в выдаче кредита
}

export interface RedirectBorrowerToVideoIdentificationEventData {
  signing_url: string
}

export interface NextWorkingDatetimeEventData {
  next_working_day_date: string
}

export interface LoanRefusalEventData {
  reason_refusal_text_ru: string
  reason_refusal_text_kk: string
}
