import { type RouteObject } from 'react-router-dom'
import { pathnames } from './pathnames'
import Layout from 'layout'

export const ROUTES: RouteObject[] = [
  {
    path: pathnames.index,
    element: <Layout />,
    children: [
      {
        path: '/test',
        lazy: () => import('pages/test')
      },
      {
        path: pathnames.lead,
        lazy: () => import('pages/LeadPages/CreateLeadPage')
      },
      {
        path: pathnames['lead-otp-confirmation'],
        lazy: () => import('pages/LeadPages/LeadOtpConfirmationPage')
      },
      {
        path: pathnames.scoring,
        lazy: () => import('pages/ScoringPage')
      },
      {
        path: pathnames.insurance,
        lazy: () => import('pages/InsurancePage')
      },
      {
        path: pathnames.photocontrol,
        lazy: () => import('pages/CarPledgeFlowPages/PhotocontrolPage')
      },
      {
        path: pathnames['waiting-collateral'],
        lazy: () => import('pages/CarPledgeFlowPages/CollateralWaitingPage')
      },
      {
        path: pathnames['waiting-estimator'],
        lazy: () => import('pages/RealEstatePledgeFlowPages/EstimatorWaitingPage')
      },
      {
        path: pathnames['pledge-confirmation'],
        lazy: () => import('pages/PledgeConfirmationPage')
      },
      {
        path: pathnames['spouse-confirmation'],
        lazy: () => import('pages/SpousePages/SpouseConfirmationPage')
      },
      {
        path: pathnames['spouse-sms-sended'],
        lazy: () => import('pages/SpousePages/SpouseSMSSendedPage')
      },
      {
        path: pathnames['egov-update-status'],
        lazy: () => import('pages/EgovUpdateStatusPage')
      },
      {
        path: pathnames['digital-docs'],
        lazy: () => import('pages/DigitalDocumentsPage')
      },
      {
        path: pathnames['documents-attachment'],
        lazy: () => import('pages/RealEstatePledgeFlowPages/AttachRealEstateDocs')
      },
      {
        path: pathnames['documents-generation'],
        lazy: () => import('pages/DocumentsGenerationPage')
      },
      {
        path: pathnames['documents-sign'],
        lazy: () => import('pages/DocumentsSignPage')
      },
      {
        path: pathnames['video-identification'],
        lazy: () => import('pages/VideoIdentification')
      },
      {
        path: pathnames['certification-center'],
        lazy: () => import('pages/RealEstatePledgeFlowPages/CertificationCenterPages/CertificationCenterBiometry')
      },
      {
        path: pathnames['certification-center-otp'],
        lazy: () =>
          import('pages/RealEstatePledgeFlowPages/CertificationCenterPages/CertificationCenterOtpConfirmation')
      },
      {
        path: pathnames['identification-results'],
        lazy: () => import('pages/VideoIdentification/WaitResultsPage')
      },
      {
        path: pathnames['await-second-pledger'],
        lazy: () => import('pages/AwaitSecondPledgerSignPage')
      },
      {
        path: pathnames['put-hard-pledge'],
        lazy: () => import('pages/WaitingPutHardPledge')
      },
      {
        path: pathnames.success,
        lazy: () => import('pages/SuccessPage')
      },
      {
        path: pathnames.rejected,
        lazy: () => import('pages/RejectedPage')
      },
      {
        path: pathnames['waiting-page'],
        lazy: () => import('pages/LoanBlockedPage')
      }
    ]
  }
]
