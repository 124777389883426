export enum TaskTrackerTypes {
  // Pre-approve process
  MESSAGE_SUPER_APP_APPLICATION_PRE_APPROVE = 'MESSAGE_SUPER_APP_APPLICATION_PRE_APPROVE',

  // Websocket events
  BORROWER_OTP_VERIFICATION_ATTEMPTS_EXCEEDED = 'BORROWER_OTP_VERIFICATION_ATTEMPTS_EXCEEDED',
  BORROWER_OTP_CODE_INCORRECT = 'BORROWER_OTP_CODE_INCORRECT',
  BORROWER_OTP_CODE_SUCCESSFUL = 'BORROWER_OTP_CODE_SUCCESSFUL',
  INTERNAL_CHECKS_SUCCESSFUL = 'INTERNAL_CHECKS_SUCCESSFUL',
  ALL_CHECKS_SUCCESSFUL = 'ALL_CHECKS_SUCCESSFUL',
  NEED_TAKE_CAR_PHOTO = 'NEED_TAKE_CAR_PHOTO',
  NECESSARY_CONFIRM_CHOOSEN_CONDITIONS = 'NECESSARY_CONFIRM_CHOOSEN_CONDITIONS',
  BORROWER_CAN_CONFIRM_CHOOSEN_CONDITIONS = 'BORROWER_CAN_CONFIRM_CHOOSEN_CONDITIONS',
  BORROWER_CANNOT_CONFIRM_CHOOSEN_CONDITIONS = 'BORROWER_CANNOT_CONFIRM_CHOOSEN_CONDITIONS',
  PLEDGE_APPROVED = 'PLEDGE_APPROVED',
  BORROWER_NEEDS_UPLOAD_REAL_ESTATE_DOCUMENTS = 'BORROWER_NEEDS_UPLOAD_REAL_ESTATE_DOCUMENTS',
  NECESSARY_PROVIDE_SPOUSE_DETAILS = 'NECESSARY_PROVIDE_SPOUSE_DETAILS',
  SPOUSE_NOT_OWN_THE_NUMBER_PROVIDED = 'SPOUSE_NOT_OWN_THE_NUMBER_PROVIDED',
  SPOUSE_OWN_THE_NUMBER_PROVIDED = 'SPOUSE_OWN_THE_NUMBER_PROVIDED',
  SPOUSE_AGREED_LOAN = 'SPOUSE_AGREED_LOAN',
  BORROWER_NECESSARY_PROVIDE_ACCESS_DIGITAL_DOCUMENTS = 'BORROWER_NECESSARY_PROVIDE_ACCESS_DIGITAL_DOCUMENTS',
  BORROWER_DIGITAL_DOCUMENTS_CODE_INCORRECT = 'BORROWER_DIGITAL_DOCUMENTS_CODE_INCORRECT',
  BORROWER_ATTEMPTS_DIGITAL_DOCUMENTS_CODE_ARE_OVER = 'BORROWER_ATTEMPTS_DIGITAL_DOCUMENTS_CODE_ARE_OVER',
  REGISTRATION_IN_BANKING_SYSTEM_STARTED = 'REGISTRATION_IN_BANKING_SYSTEM_STARTED',
  REGISTRATION_IN_BANK_SYSTEM_COMPLETED = 'REGISTRATION_IN_BANK_SYSTEM_COMPLETED',
  DOCUMENTS_GENERATED = 'DOCUMENTS_GENERATED',
  DOCUMENT_VALIDATION_SUCCESS = 'DOCUMENT_VALIDATION_SUCCESS',
  NEED_WAIT_WORK_TIME = 'NEED_WAIT_WORK_TIME',
  BORROWER_NEED_INITIATE_DOCUMENTS_SIGNING = 'BORROWER_NEED_INITIATE_DOCUMENTS_SIGNING',
  BORROWER_NEED_INITIATE_DOCUMENTS_SIGNING_FORENSICS = 'BORROWER_NEED_INITIATE_DOCUMENTS_SIGNING_FORENSICS',
  BORROWER_VIDEO_IDENTIFICATION_FAILURE_FORENSICS = 'BORROWER_VIDEO_IDENTIFICATION_FAILURE_FORENSICS',
  BORROWER_VIDEO_IDENTIFICATION_SUCCESSFUL_FORENSICS = 'BORROWER_VIDEO_IDENTIFICATION_SUCCESSFUL_FORENSICS',
  BORROWER_NEED_INITIATE_DOCUMENTS_SIGNING_DID_AITU = 'BORROWER_NEED_INITIATE_DOCUMENTS_SIGNING_DID_AITU',
  BORROWER_SIGNATURE_CONFIRMATION_OTP_CODE_SUCCESSFUL = 'BORROWER_SIGNATURE_CONFIRMATION_OTP_CODE_SUCCESSFUL',
  BORROWER_SIGNATURE_CONFIRMATION_OTP_CODE_INCORRECT = 'BORROWER_SIGNATURE_CONFIRMATION_OTP_CODE_INCORRECT',
  BORROWER_SIGNATURE_CONFIRMATION_OTP_VERIFICATION_ATTEMPTS_EXCEEDED = 'BORROWER_SIGNATURE_CONFIRMATION_OTP_VERIFICATION_ATTEMPTS_EXCEEDED',
  BORROWER_SIGNATURE_CONFIRMATION_ENTERED_PHONE_VERIFICATION_CODE = 'BORROWER_SIGNATURE_CONFIRMATION_ENTERED_PHONE_VERIFICATION_CODE',
  REDIRECT_BORROWER_TO_VIDEO_IDENTIFICATION = 'REDIRECT_BORROWER_TO_VIDEO_IDENTIFICATION',
  BORROWER_VIDEO_IDENTIFICATION_FAILURE = 'BORROWER_VIDEO_IDENTIFICATION_FAILURE',
  BORROWER_VIDEO_IDENTIFICATION_SUCCESSFUL = 'BORROWER_VIDEO_IDENTIFICATION_SUCCESSFUL',
  BORROWER_NEED_WAIT_SPOUSE_SIGNATURE = 'BORROWER_NEED_WAIT_SPOUSE_SIGNATURE',
  BORROWER_INTERNAL_CHECKS_CONTINUE = 'BORROWER_INTERNAL_CHECKS_CONTINUE',
  BORROWER_PLEDGE_CONTINUE = 'BORROWER_PLEDGE_CONTINUE',
  LOAN_SUCCESSFULLY_PROCESSED = 'LOAN_SUCCESSFULLY_PROCESSED',

  // User interaction events
  BORROWER_INITIATED_LOAN = 'BORROWER_INITIATED_LOAN',
  LOAN_REFUSAL = 'LOAN_REFUSAL', // Reject event
  BORROWER_CONDITIONS_SELECTED = 'BORROWER_CONDITIONS_SELECTED',
  BORROWER_ENTERED_PHONE_VERIFICATION_CODE = 'BORROWER_ENTERED_PHONE_VERIFICATION_CODE',
  BORROWER_REQUESTED_NEW_PHONE_VERIFICATION_CODE = 'BORROWER_REQUESTED_NEW_PHONE_VERIFICATION_CODE',
  BORROWER_CHOSE_FREEDOM = 'BORROWER_CHOSE_FREEDOM',
  BORROWER_CHOSE_ANOTHER_COMPANY = 'BORROWER_CHOSE_ANOTHER_COMPANY', // Reject event
  BORROWER_PROVIDED_CAR_PHOTO = 'BORROWER_PROVIDED_CAR_PHOTO',
  COLLATERAL_DECISION_RECEIVED = 'COLLATERAL_DECISION_RECEIVED',
  ESTIMATION_ALL_CLIENT_DOCUMENTS = 'ESTIMATION_ALL_CLIENT_DOCUMENTS',
  ESTIMATION_RESULT_RECEIVED = 'ESTIMATION_RESULT_RECEIVED',
  BORROWER_AGREED_CONDITIONS_LOAN = 'BORROWER_AGREED_CONDITIONS_LOAN',
  BORROWER_PROVIDED_SPOUSE_PHONE = 'BORROWER_PROVIDED_SPOUSE_PHONE',
  BORROWER_IS_NOT_MARRIED = 'BORROWER_IS_NOT_MARRIED',
  BORROWER_REPORTED_THAT_UPDATED_MARITAL_STATUS = 'BORROWER_REPORTED_THAT_UPDATED_MARITAL_STATUS',
  BORROWER_ENTERED_DIGITAL_DOCUMENTS_CODE = 'BORROWER_ENTERED_DIGITAL_DOCUMENTS_CODE',
  BORROWER_REQUESTED_NEW_PROVIDING_DIGITAL_DOCUMENTS_CODE = 'BORROWER_REQUESTED_NEW_PROVIDING_DIGITAL_DOCUMENTS_CODE',
  BORROWER_INITIATED_DOCUMENTS_SIGNING = 'BORROWER_INITIATED_DOCUMENTS_SIGNING',
  BORROWER_INITIATED_VIDEO_IDENTIFICATION = 'BORROWER_INITIATED_VIDEO_IDENTIFICATION',
  BORROWER_DATA_TRANSFERRED_TO_FORENSICS = 'BORROWER_DATA_TRANSFERRED_TO_FORENSICS',
  BORROWER_RESULT_BIOMETRY_RECEIVED = 'BORROWER_RESULT_BIOMETRY_RECEIVED'
}
